import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();

  const handleLogoutClick = () => {
    window.location.reload();
  };

  return (
    <div className="w-full h-[10vh] bg-gray-700 flex justify-between p-4">
      <h1 className="text-3xl text-white">Healdco Dashboard</h1>
      <nav>
        <ul className="flex gap-5">
          <li>
            <Link
              className={`block text-xl text-white w-full px-4 py-2 transition ease-in duration-100 rounded-lg ${
                location.pathname === "/" ? "bg-gray-400" : ""
              }`}
              to={"/"}
            >
              Healdco
            </Link>
          </li>
          <li>
            <Link
              className={`block text-xl text-white w-full px-4 py-2  transition ease-in duration-100 rounded-lg ${
                location.pathname === "/dunlop" ? "bg-gray-400" : ""
              }`}
              to={"/dunlop"}
            >
              Dunlop
            </Link>
          </li>
        </ul>
      </nav>

      <button
        onClick={handleLogoutClick}
        className="border px-6 py-1 text-lg text-white rounded-lg flex items-center justify-center"
      >
        <p> Logout</p>
      </button>
    </div>
  );
};

export default Navigation;
