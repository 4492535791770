import { useState } from "react";
// UI
import Spinner from "../components/Container/Spinner";
// hooks
import useInput from "./Components/Hooks/user-input";
// inputs
import Input from "./Components/Inputs/Input";
import PasswordInput from "./Components/Inputs/PasswordInput";
// Message
import inputErrorMessage from "./Components/Messages/inputErrorMessage";

const Registration = ({ onSubmit }) => {
  const [formIsError, setFormIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const clearErrorHandler = () => {
    setFormIsError(false);
  };
  // styles
  const inputStyle =
    "border border-black p-2 w-full flex items-center justify-between";

  const {
    value: emailInput,
    isValid: emailIsValid,
    isTouched: emailIsTouched,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHanlder,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const {
    value: passwordInput,
    isValid: passwordIsValid,
    HasError: passwordHasError,
    inputChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    inputFocusHandler: passwordFocusHanlder,
    isFocus: passwordIsFocus,
    reset: passwordReset,
  } = useInput((value) => value.trim("") !== 0);

  let formIsValid = emailIsValid && passwordIsValid;

  const formSubmitionHandler = async (event) => {
    event.preventDefault();
    if (!formIsValid) return;

    const loginFromState = {
      email: emailInput,
      password: passwordInput,
    };

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://api.dunloptireslebanon.com/public/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginFromState),
        }
      );

      if (!response.ok) {
        setIsLoading(false);
        setFormIsError(true);
        console.log(response);
      } else {
        setIsLoading(false);
        const data = await response.json();
        onSubmit(data.token);
      }
    } catch (error) {
      console.log(Error);
    }

    emailReset();
    passwordReset();
  };

  return (
    <div className="h-[100vh] bg-gray-400 flex items-center justify-center drop-shadow-[4xl]">
      <div className="w-[40%] bg-white p-10 flex flex-col gap-4">
        <div className="mb-2 text-center">
          <h1 className=" text-2xl font-bold ">Log in</h1>
          {formIsError && (
            <p className="mt-1 text-sm text-red-600">
              Failed to login please try again later
            </p>
          )}
        </div>
        <form onSubmit={formSubmitionHandler}>
          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"email"}
            id={"email"}
            value={emailInput}
            placeholder="Email Address"
            onChange={(event) => {
              emailChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={emailBlurHanlder}
            hasError={emailHasError}
            errorMessage={inputErrorMessage.email}
          />

          <PasswordInput
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            name={"password"}
            id={"password"}
            placeholder={"Your password"}
            value={passwordInput}
            onChange={(event) => {
              passwordChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={passwordBlurHandler}
            onFocus={passwordFocusHanlder}
            isFocus={passwordIsFocus}
            hasError={passwordHasError}
            errorMessage={inputErrorMessage.passwordConfirm}
          />

          <button className="border border-black px-4 py-2 transition ease-in duration-300 hover:bg-black hover:text-white  flex items-center gap-x-2 ">
            {isLoading && <Spinner />} Log in
          </button>
        </form>
      </div>
    </div>
  );
};

export default Registration;
