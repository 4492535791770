import { useState } from "react";

import { Routes, Route } from "react-router-dom";

import Registration from "./form/Registration";

import Navigation from "./layout/Navigation/Navigation";
import Dunlop from "./pages/Dunlop/Dunlop";
import Headlco from "./pages/Healco/Headlco";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState("");

  const loginHandler = (token) => {
    setIsLoggedIn(true);
    setToken(token);
  };

  return (
    <div className="App max-h-[100vh]">
      {isLoggedIn ? (
        <>
          <Navigation />
          <Routes>
            <Route index element={<Headlco token={token} />} />
            <Route path="dunlop" element={<Dunlop token={token} />} />
          </Routes>
        </>
      ) : (
        <Registration onSubmit={loginHandler} />
      )}
    </div>
  );
};

export default App;
