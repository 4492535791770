import { useState, useEffect } from "react";
import "./healdco.css";
import Spinner from "../../components/Container/Spinner";

const HealdCo = ({ token }) => {
  const [data, setData] = useState([]);
  const [dataIsFetching, setDataIsFetching] = useState(true);
  const [selectedID, setSelectedID] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [exportIsLoading, setExporIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.dunloptireslebanon.com/public/api/healdco",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.log("no");
      } else {
        const data = await response.json();
        setData(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDataIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://api.dunloptireslebanon.com/public/api/healdco/${id}`,
        {
          method: "DELETE",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        setIsLoading(false);
        alert(`failed to delete, Error: ${response.status}`);
      } else {
        setIsLoading(false);
        const data = await response.json();
        fetchData();
        alert(`${data.status}, ${data.message}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = async () => {
    setExporIsLoading(true);
    try {
      const response = await fetch(
        `https://api.dunloptireslebanon.com/public/api/healdco/export/pdf`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        setExporIsLoading(false);
        alert(`failed to export, Error: ${response.status}`);
      } else {
        setExporIsLoading(false);
        const data = await response.json();
        console.log(data);
        const downloadLink = document.createElement("a");
        downloadLink.href = data.url;
        downloadLink.setAttribute("download", "filename.pdf");
        document.body.appendChild(downloadLink);

        // Programmatically click the link to initiate the download
        downloadLink.click();

        // Clean up the temporary anchor element
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectedObject = data?.find((obj) => obj.id === selectedID);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "UTC",
    };
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  }

  if (dataIsFetching) {
    return (
      <div className="flex items-center justify-center w-[90vw] h-[90vh]">
        <p>Loading...</p>
      </div>
    );
  }
  if (data.length === 0) {
    return (
      <div className="flex items-center justify-center w-[90vw] h-[90vh]">
        <p>No Data</p>
      </div>
    );
  }

  return (
    <div className="custom-grid max-h-[90vh]">
      <div className="flex flex-col justify-between bg-gray-500 text-white text-xl h-full p-2">
        <nav className="flex flex-col gap-y-1 max-h-full overflow-auto">
          {data.map((item) => (
            <ul
              key={item.id}
              onClick={() => setSelectedID(item.id)}
              className={`flex items-center gap-x-2 cursor-pointer transition ease-in duration-100 hover:bg-gray-700 p-4 ${
                item.id === selectedID ? "bg-gray-700" : ""
              }`}
            >
              <li>{item.firstname}</li>
              <li>{item.lastname}</li>
            </ul>
          ))}
        </nav>
        <div className="">
          <button
            onClick={handleExport}
            className="py-2 px-2 border border-white mt-4 hover:bg-white hover:border-gray-700 hover:text-gray-700 w-full flex items-center justify-center gap-x-2"
          >
            {exportIsLoading && <Spinner />} Export list
          </button>
        </div>
      </div>
      <div className="p-4 grid-container ">
        <span className="flex flex-col gap-1 ">
          <h4 className="font-bold">First Name:</h4>
          <p>{selectedObject?.firstname}</p>
        </span>
        <span className="flex  flex-col gap-1">
          <h4 className="font-bold">Last Name:</h4>
          <p>{selectedObject?.lastname}</p>
        </span>
        <span className="flex flex-col gap-1">
          <h4 className="font-bold">Phone Number:</h4>
          <p>{selectedObject?.phonenumber}</p>
        </span>
        <span className="flex flex-col gap-1">
          <h4 className="font-bold">Email:</h4>
          <p>{selectedObject?.email}</p>
        </span>
        <span className="flex flex-col gap-1">
          <h4 className="font-bold">Brand:</h4>
          <p>{selectedObject?.brand}</p>
        </span>
        <span className="flex flex-col gap-1">
          <h4 className="font-bold">Type of appliance:</h4>
          <p>{selectedObject?.typeOfAppliance}</p>
        </span>
        <span className="flex flex-col gap-1 ">
          <h4 className="font-bold">Modale Code:</h4>
          <p>{selectedObject?.modaleCode}</p>
        </span>
        <span className="flex flex-col gap-1">
          <h4 className="font-bold">Serial Number:</h4>
          <p>{selectedObject?.serialNumber}</p>
        </span>
        <span className="flex flex-col gap-1">
          <h4 className="font-bold">Dealer Name:</h4>
          <p>{selectedObject?.dealerName}</p>
        </span>
        <span className="flex flex-col gap-1">
          <h4 className="font-bold">Warranty Name</h4>
          <p>{selectedObject?.warrantyName}</p>
        </span>
        <span className="flex flex-col gap-1">
          <h4 className="font-bold">Form Submission Time:</h4>
          <p>{formatDate(selectedObject?.updated_at)}</p>
        </span>
        <span className="flex flex-col ">
          <button
            className="text-white bg-gray-700 py-2 rounded-md text-lg w-3/4 mx-auto transition ease-in duration-300 border hover:bg-white hover:text-gray-700 hover:border-gray-700 flex items-center gap-x-2  justify-center"
            onClick={() => handleDelete(selectedObject.id)}
          >
            {isLoading && <Spinner />} Delete warranty
          </button>
        </span>
      </div>
    </div>
  );
};

export default HealdCo;
